@import './index-tablet.module.scss';
@import './index-mobile.module.scss';
.theAcademicAdvisors {
  margin: 0;
  width: 926px;
  position: relative;
  font-size: inherit;
  line-height: 68px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.tEXT,
.vector {
  width: 1216px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  max-width: 100%;
}
.vector {
  overflow-x: auto;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 50px 46px;
  min-height: 2258px;
}
.clSitedesktopadvisors,
.tEXTParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tEXTParent {
  align-self: stretch;
  align-items: center;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 40px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-41xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}
.clSitedesktopadvisors {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  align-items: flex-start;
  letter-spacing: normal;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
