.theIndustryAdvisors {
  margin: 0;
  width: 926px;
  position: relative;
  font-size: inherit;
  line-height: 68px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.fRAME,
.fRAME1 {
  display: flex;
  max-width: 100%;
}
.fRAME1 {
  width: 1216px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 50px 46px;
  min-height: 1118px;
}
.fRAME {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 40px 0;
  text-align: center;
  font-size: var(--font-size-41xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}
@media screen and (max-width: 1200px) {
  .fRAME {
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .theIndustryAdvisors {
    font-size: var(--font-size-29xl);
    line-height: 54px;
  }
}
@media screen and (max-width: 750px) {
  .fRAME1 {
    gap: 50px 46px;
  }
  .fRAME {
    gap: 40px 0;
    padding-top: var(--padding-15xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .theIndustryAdvisors {
    font-size: var(--font-size-17xl);
    line-height: 41px;
  }
}
