.speakerNotesFill0Wght300GrIcon {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.strategicAdvisoryServices {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.weProvideStrategic {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 28px;
  color: var(--color-dimgray-100);
}
.expertises,
.expertises1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.expertises1 {
  align-self: stretch;
  gap: 10px 0;
}
.expertises {
  width: 594px;
  border-radius: var(--br-9xl);
  background-color: var(--color-gray-100);
  padding: var(--padding-21xl);
  box-sizing: border-box;
  gap: 18px 0;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}
/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .expertises {
    width: 100%;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .expertises {
    padding: 30px;
  }
  .weProvideStrategic {
    color: #575757;
    font-family: Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}
