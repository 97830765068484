@mixin mobile {
  .theAcademicAdvisors {
    color: #0e1435;
    text-align: center;
    font-family: Onest;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 146.667% */
    text-transform: capitalize;
  }
  .tEXT,
  .vector {
    width: 1216px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
  }
  .vector {
    overflow-x: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 50px 46px;
    min-height: 2258px;
  }
  .clSitedesktopadvisors,
  .tEXTParent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .tEXTParent {
    align-self: stretch;
    align-items: center;
    padding: var(--padding-xl);
    box-sizing: border-box;
    gap: 40px 0;
    max-width: 100%;
    text-align: center;
    font-size: var(--font-size-41xl);
    color: var(--color-gray-200);
    font-family: var(--font-onest);
  }
  .clSitedesktopadvisors {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    align-items: flex-start;
    letter-spacing: normal;
  }
}
