@mixin tablet {
  .frameBIcon {
    height: 38px;
    width: 144.2px;
    position: relative;
    flex-shrink: 0;
  }
  .contactUs {
    position: relative;
    font-weight: 500;
  }
  .arrowOutwardFill0Wght400GrIcon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  .contactUsButton,
  .frameA {
    display: flex;
    flex-direction: row;
  }
  .contactUsButton {
    border-radius: var(--br-5xl);
    background-color: var(--color-white);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
    border: 1px solid var(--color-whitesmoke);
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-sm) var(--padding-12xl) var(--padding-sm) var(--padding-11xl);
    gap: 0 6px;
    white-space: nowrap;
  }
  .frameA {
  }
  .clSitedesktoplandingPage {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: normal;
  }
}
