.crypticLabsShaping {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 68px;
  font-weight: 700;
  font-family: inherit;
}
.welcomeToCryptic {
  width: 845px;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 30px;
  font-family: var(--font-inter);
  color: var(--color-dimgray-200);
  display: inline-block;
  max-width: 100%;
}
.welcomeText {
  width: 926px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px 0;
  max-width: 100%;
  flex-shrink: 0;
}
.exploreCrypticAdvisors {
  position: relative;
  font-weight: 600;
}
.arrowOutwardFill0Wght400GrIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.advisorContainer {
  border-radius: var(--br-18xl);
  background-color: var(--color-tomato);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-21xl);
  box-sizing: border-box;
  gap: 0 10px;
  max-width: 100%;
  white-space: nowrap;
}
.contactUs {
  position: relative;
  font-weight: 500;
}
.arrowOutwardFill0Wght400GrIcon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.advisorContainer1 {
  border-radius: var(--br-18xl);
  background-color: var(--color-white);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-whitesmoke);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-20xl);
  gap: 0 10px;
  white-space: nowrap;
  color: var(--color-gray-200);
}
.commitmentSection,
.ourTeamSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 26px;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
}
.ourTeamSection {
  align-self: stretch;
  height: 496px;
  background-color: var(--color-gray-100);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-49xl) var(--padding-xl) var(--padding-61xl);
  box-sizing: border-box;
  gap: 28px 0;
  text-align: center;
  font-size: var(--font-size-41xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .ourTeamSection {
    height: auto;
    padding-bottom: 0;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .ourTeamSection {
    font-size: 34px;
    padding-top: 20px;
  }
  .crypticLabsShaping {
    line-height: 44px;
  }
  .welcomeToCryptic {
    color: #474b64;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .commitmentSection {
    flex-direction: column;
    gap: 20px;
  }
  .advisorContainer1,
  .advisorContainer {
    width: 100%;
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}
