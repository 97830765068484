.stepIntoCryptic {
  width: 144.2px;
  height: 38px;
  position: relative;
}
.stepIntoThe {
  align-self: stretch;
  position: relative;
  line-height: 28px;
}
.enigmaText {
  width: 514px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px 0;
  max-width: 100%;
}
.contactUs {
  position: relative;
  font-weight: 500;
}
.arrowOutwardFill0Wght400GrIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.contactUsButton,
.contactUsText {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contactUsText {
  border-radius: var(--br-18xl);
  background-color: var(--color-white);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-whitesmoke);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-22xl) var(--padding-5xl) var(--padding-20xl);
  gap: 0 10px;
  white-space: nowrap;
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}
.contactUsButton {
  width: 1216px;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.backgroundRectangle,
.copyright2024Cryptic {
  width: 1216px;
  position: relative;
  max-width: 100%;
}
.backgroundRectangle {
  height: 1px;
  background-color: var(--color-gainsboro);
}
.copyright2024Cryptic {
  line-height: 28px;
  color: var(--color-silver);
  text-align: center;
  display: inline-block;
}
.worldOfCryptic {
  align-self: stretch;
  border-radius: var(--br-61xl) var(--br-61xl) 0 0;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-21xl) var(--padding-21xl);
  box-sizing: border-box;
  gap: 60px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  font-family: var(--font-onest);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .worldOfCryptic {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 40px;
    gap: 40px;
  }
  .contactUsButton {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .enigmaText,
  .stepIntoThe {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .contactUsText {
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 40px;
  }
}
