.vectorIcon {
  height: 38px;
  width: 144.2px;
  position: relative;
}
.contactUs {
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-onest);
  color: var(--color-gray-200);
  text-align: left;
}
.arrowOutwardFill0Wght400GrIcon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.fRAME,
.vectorParent {
  display: flex;
  flex-direction: row;
}
.fRAME {
  cursor: pointer;
  border: 1px solid var(--color-whitesmoke);
  padding: var(--padding-sm) var(--padding-12xl) var(--padding-sm) var(--padding-11xl);
  background-color: var(--color-white);
  border-radius: var(--br-5xl);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 6px;
  white-space: nowrap;
}
.fRAME:hover {
  background-color: var(--color-gainsboro-100);
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
}
.vectorParent {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-11xl) var(--padding-xl);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .vectorParent {
    justify-content: center;
  }
  .fRAME {
    display: none;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
}
