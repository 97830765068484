.meetTheAdvisors {
  margin: 0;
  width: 580px;
  position: relative;
  font-size: inherit;
  line-height: 62px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  min-width: 580px;
  max-width: 100%;
}
.learnMore {
  position: relative;
  font-weight: 500;
}
.arrowOutwardFill0Wght400GrIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.advisors,
.advisors1 {
  display: flex;
  flex-direction: row;
}
.advisors1 {
  border-radius: var(--br-18xl);
  background-color: var(--color-white);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-whitesmoke);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-20xl);
  gap: 0 10px;
  white-space: nowrap;
  font-size: var(--font-size-xl);
}
.advisors {
  width: 1216px;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.advisoryServices,
.professorImages {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.professorImages {
  width: 1216px;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 50px 46px;
  min-height: 1118px;
  font-size: var(--font-size-9xl);
}
.advisoryServices {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 40px 0;
  text-align: left;
  font-size: var(--font-size-33xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .professorImages {
    width: 100%;
    justify-content: center;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .advisoryServices {
    padding-top: 20px;
  }
  .advisors {
    width: 100%;
    flex-direction: column;
  }
  .meetTheAdvisors {
    width: 100%;
    min-width: auto;
    color: #0e1435;
    text-align: center;
    font-family: Onest;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 146.667% */
    text-transform: capitalize;
  }
  .advisors1 {
    width: 100%;
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}
