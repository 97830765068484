@mixin tablet {
  .getInTouch {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 68px;
    text-transform: capitalize;
    font-weight: 700;
    font-family: inherit;
  }
  .frameGetInTouch {
    width: 926px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }
  .sendMessage {
    position: relative;
    font-size: var(--font-size-xl);
    font-weight: 600;
    font-family: var(--font-onest);
    color: var(--color-white);
    text-align: left;
  }
  .textCopyright {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xl) var(--padding-21xl);
    background-color: var(--color-tomato);
    border-radius: var(--br-18xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .textCopyright:hover {
    background-color: #ff4d4d;
  }
  .contactcrypticlabsorg {
    position: relative;
    white-space: nowrap;
  }
  .rectangleBackground {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-xl);
  }
  .frameNameEmail {
    width: 764px;
    border-radius: 48px;
    background-color: var(--color-gray-100);
    align-items: flex-start;
    padding: var(--padding-33xl) 64px;
    box-sizing: border-box;
    gap: var(--gap-35xl);
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-dimgray-300);
  }
  .clSitedesktopcontact,
  .frameInputForm,
  .frameNameEmail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .frameInputForm {
    align-self: stretch;
    align-items: center;
    padding: var(--padding-49xl) var(--padding-xl) var(--padding-61xl);
    box-sizing: border-box;
    gap: 28px 0;
    max-width: 100%;
    text-align: center;
    font-size: var(--font-size-41xl);
    color: var(--color-gray-200);
    font-family: var(--font-onest);
  }
  .clSitedesktopcontact {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    align-items: flex-start;
    letter-spacing: normal;
  }
}
