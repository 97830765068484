.expertises,
.ourAreasOf {
  width: 1216px;
  max-width: 100%;
}
.ourAreasOf {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 62px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.expertises {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 28px 22px;
  min-height: 638px;
  text-align: left;
  font-size: var(--font-size-5xl);
}
.areasExpertiseTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 40px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-33xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}
@media screen and (max-width: 1050px) {
  .ourAreasOf {
    font-size: var(--font-size-23xl);
    line-height: 50px;
  }
  .areasExpertiseTitle {
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .areasExpertiseTitle {
    gap: 40px 0;
  }
}
@media screen and (max-width: 450px) {
  .ourAreasOf {
    font-size: var(--font-size-12xl);
    line-height: 37px;
  }
  .areasExpertiseTitle {
    padding-top: var(--padding-15xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
