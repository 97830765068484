.leaveUsA {
  position: relative;
}
.frameJohnCarter,
.textFieldMessage,
.textMessage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textFieldMessage {
  border: 1px solid var(--color-gainsboro-200);
  background-color: var(--color-white);
  height: 178px;
  width: auto;
  outline: 0;
  align-self: stretch;
  border-radius: var(--br-5xl);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-5xl);
  font-family: var(--font-onest);
  font-size: var(--font-size-lg);
  color: var(--color-dimgray-300);
}
.frameJohnCarter,
.textMessage {
  max-width: 100%;
}
.textMessage {
  width: 636px;
  flex-direction: column;
  gap: 12px 0;
}
.frameJohnCarter {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 36px 30px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-300);
  font-family: var(--font-onest);
}

.johnCarter,
.name {
  position: relative;
}
.johnCarter {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--font-onest);
  font-size: var(--font-size-lg);
  background-color: transparent;
  height: 23px;
  color: var(--color-dimgray-300);
  text-align: left;
  display: inline-block;
}
.frameSetNameEmail,
.nameJohnCarter {
  display: flex;
  justify-content: flex-start;
}
.nameJohnCarter {
  align-self: stretch;
  border-radius: var(--br-17xl);
  background-color: var(--color-white);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-gainsboro-200);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xl);
}
.frameSetNameEmail {
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px 0;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-300);
  font-family: var(--font-onest);
}

.rectangleBackground {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.textCopyright {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xl) var(--padding-21xl);
  background-color: var(--color-tomato);
  border-radius: var(--br-18xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.textCopyright:hover {
  background-color: #ff4d4d;
}

.sendMessage {
  position: relative;
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-family: var(--font-onest);
  color: var(--color-white);
  text-align: left;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .name {
    color: #171717;
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .nameJohnCarter {
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #747474;
    font-family: Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rectangleBackground {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-xl);
  }
  .textCopyright {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xl) var(--padding-21xl);
    background-color: var(--color-tomato);
    border-radius: var(--br-18xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
  }
}
