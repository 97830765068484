.image59Icon {
  height: 400px;
  border-radius: var(--br-9xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.drEricS,
.ericMaskinIs,
.image59Icon {
  align-self: stretch;
  position: relative;
}
.drEricS {
  margin: 0;
  height: 36px;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.ericMaskinIs {
  font-size: var(--font-size-base);
  line-height: 28px;
  color: var(--color-dimgray-100);
}
.fRAME,
.fRAME1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.fRAME1 {
  align-self: stretch;
  gap: 8px 0;
}
.fRAME {
  width: 372px;
  flex-shrink: 0;
  gap: 20px 0;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}
/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .fRAME {
    width: 45%;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .fRAME {
    width: 100%;
  }
}
