.scottGraham5fnmwej4taaUnsplIcon {
  height: 322px;
  width: 566px;
  position: relative;
  border-radius: var(--br-11xl);
  object-fit: cover;
  min-height: 322px;
  max-width: 100%;
}
.atCrypticLabs,
.ourCommitment {
  align-self: stretch;
  position: relative;
}
.ourCommitment {
  margin: 0;
  font-size: inherit;
  line-height: 58px;
  font-weight: 700;
  font-family: inherit;
}
.atCrypticLabs {
  font-size: var(--font-size-base);
  line-height: 28px;
  color: var(--color-dimgray-100);
}
.frameC,
.ourTeamText {
  display: flex;
  max-width: 100%;
}
.frameC {
  width: 560px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-31xl) 0;
  box-sizing: border-box;
  gap: 24px 0;
  min-width: 560px;
}
.ourTeamText {
  width: 1216px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.atCrypticLabs1,
.ourExpertTeam {
  align-self: stretch;
  position: relative;
}
.ourExpertTeam {
  margin: 0;
  font-size: inherit;
  line-height: 58px;
  font-weight: 700;
  font-family: inherit;
}
.atCrypticLabs1 {
  font-size: var(--font-size-base);
  line-height: 28px;
  color: var(--color-dimgray-100);
}
.ourExpertTeamParent {
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-31xl) 0;
  box-sizing: border-box;
  gap: 24px 0;
  max-width: 100%;
}
.ourTeamTextChild {
  height: 322px;
  width: 560px;
  position: relative;
  border-radius: var(--br-11xl);
  object-fit: cover;
  max-width: 100%;
}
.ourLogo,
.ourTeamText1 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.ourTeamText1 {
  width: 1216px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.ourLogo {
  align-self: stretch;
  border-radius: 0 0 var(--br-61xl) var(--br-61xl);
  background-color: var(--color-gray-100);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-xl) var(--padding-93xl);
  box-sizing: border-box;
  gap: 90px 0;
  min-height: 1042px;
  text-align: left;
  font-size: var(--font-size-27xl);
  color: var(--color-gray-200);
  font-family: var(--font-onest);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  .ourTeamText {
    width: 100%;
    gap: 30px;
  }
  .scottGraham5fnmwej4taaUnsplIcon,
  .ourTeamTextChild {
    width: 40%;
  }
  .frameC {
    width: 100%;
    min-width: auto;
  }
  .ourExpertTeamParent {
    width: 100%;
  }

  .ourLogo {
    gap: 30px;
    padding-bottom: 30px;
    min-height: auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  .ourLogo {
    padding-top: 20px;
  }
  .ourTeamText {
    flex-direction: column-reverse;
  }
  .frameC,
  .ourExpertTeamParent {
    gap: 10px;
    padding-bottom: 0;
  }
  .ourCommitment,
  .ourExpertTeam {
    color: #0e1435;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 45px */
  }
  .atCrypticLabs,
  .atCrypticLabs1 {
    color: #575757;
    font-family: Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .scottGraham5fnmwej4taaUnsplIcon,
  .ourTeamTextChild {
    width: 100%;
  }
  .ourTeamText1 {
    flex-direction: column;
  }
}
