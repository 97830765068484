@mixin mobile {
  .frameBIcon {
    height: 38px;
    width: 144.2px;
    position: relative;
    flex-shrink: 0;
  }
  .contactUs {
    position: relative;
    font-weight: 500;
  }
  .arrowOutwardFill0Wght400GrIcon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  .contactUsButton,
  .frameA {
    display: flex;
    flex-direction: row;
  }
  .contactUsButton {
    display: none;
  }
  .frameA {
    justify-content: center;
  }
  .clSitedesktoplandingPage {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: normal;
  }
}
